import React from 'react';
import Headline from '../../ui/Headline';
import Container from '../../ui/Grid/Container';
import Row from '../../ui/Grid/Row';
import Col from '../../ui/Grid/Col';
import c from 'classnames';
import * as styles from './styles.module.scss';
import classNames from 'classnames';

type TextProps = {
  headline?: string;
  headlineLevel?: 1 | 2;
  textAboveHeadline?: string;
  text?: string;
  btnText?: string;
  btnTarget?: string;
  background?: 'white' | 'gray';
  colSpan?: 4 | 6 | 8 | 10 | 12;
  centered?: boolean;
  isInsideModal?: boolean;
};

const Text = ({
  headline,
  textAboveHeadline,
  background = 'white',
  text,
  btnText,
  btnTarget,
  headlineLevel = 2,
  colSpan = 4,
  centered = false,
  isInsideModal = false,
}: TextProps) => {
  return (
    <Container
      additionalClass={c(styles.container, {
        [styles.bgGray]: background === 'gray',
        [styles.isInsideModal]: isInsideModal,
      })}
    >
      <Row additionalClass="max-w-[700px] mx-auto">
        <div className="max-w-[700px] col-span-full px-30">
          <Col mobile={12} tablet={12} tabletLandscape={10} desktop={colSpan} centered>
            {textAboveHeadline && (
              <div
                dangerouslySetInnerHTML={{
                  __html: textAboveHeadline,
                }}
                className={styles.textAboveHeadline}
              ></div>
            )}
            {headline && (
              <Headline
                additionalClass={classNames(styles.heading, centered ? styles.isCentered : '')}
                classLevel={1}
                level={headlineLevel}
              >
                {headline}
              </Headline>
            )}
            {text && (
              <div
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
                className={classNames(styles.para, centered ? styles.isCentered : '')}
              />
            )}
            {btnText && (
              <div className={styles.btnWrapper}>
                <a href={btnTarget} target="_blank" className={styles.btn} rel="noreferrer">
                  {btnText}
                </a>
              </div>
            )}
          </Col>
        </div>
      </Row>
    </Container>
  );
};

export default Text;
