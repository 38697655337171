import React from "react";
import arrow from "./icons/arrow.svg";
import logo from "./icons/logo.svg";
import logoClaim from "./icons/logoClaim.svg";
import instagram from "./icons/instagram.svg";
import facebook from "./icons/facebook.svg";
import check from "./icons/check.svg";
import stamp from "./icons/stamp.svg";
import sparkasse from "./icons/SparkasseGroup.svg";
import contest from "./icons/badge.svg";

export type IconType =
  | "logoClaim"
  | "logo"
  | "arrow"
  | "facebook"
  | "instagram"
  | "check"
  | "stamp"
  | "sparkasse"
  | "contest";

type IconProps = {
  name: IconType;
  width?: number;
  height?: number;
  additionalClass?: string;
};

const Icon = ({ name, width, height, additionalClass }: IconProps) => {
  if (!name) {
    return null;
  }

  const icons = {
    logo,
    logoClaim,
    arrow,
    facebook,
    instagram,
    check,
    stamp,
    sparkasse,
    contest,
  };

  const CurrentIcon = icons[name];

  if (!CurrentIcon) return null;

  return (
    <CurrentIcon width={width} height={height} className={additionalClass} />
  );
};

export default Icon;
