import React from "react";
import Icon from "../../components/ui/Icon";
import * as styles from "./styles.module.scss";

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.container}>
        <div>
          <nav>
            <a aria-current="page" href="https://www.vgh.de/" className="w-150">
              <Icon name="logo" additionalClass={styles.logo} />
            </a>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
