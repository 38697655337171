import React from 'react';
import Kpi from '../components/content/Kpi';
import Layout from '../modules/Layout';
import Text from '../components/content/Text';
import Newsletter from '../components/content/Newsletter';

const KpiItems = [
  {
    title: 'Mehr als',
    amount: '<strong>270</strong>',
    text: 'Jahre individuelle und kompetente Beratung in Niedersachsen und Bremen sagen eigentlich alles.',
  },
  {
    title: 'Etwa',
    amount: '<strong>1,8 Mio.</strong>',
    text: 'Kunden sagen das Gleiche – genauso wie jede Menge Auszeichnungen von Focus Money bis hin zu Stiftung Warentest.',
  },
  {
    title: 'Rund',
    amount: '<strong>1.100</strong>',
    text: 'Vertretungen und Sparkassen, die Sie persönlich vor Ort beraten statt anonym online.',
  },
];

const HomePage = () => (
  <Layout
    pageName="Jetzt Newsletter abonnieren & Vorteile sichern"
    description="⭐️Gewinne einen von 20 Rossmann-Gutscheinen! ⚡️
    Erhalte Expertentipps &-Tricks 🧡 Profitiere von exklusiven Aktionen nur für Abonnenten."
  >
    <Newsletter headline="Informieren, inspirieren, profitieren: Der VGH Newsletter – für alle, die mehr wollen" />
    <Text
      background="gray"
      text="<ul><li>kostenlos die neuesten Infos rund um Versicherungen erhalten</li><li>als Erster von exklusiven Aktionen erfahren</li><li>von Experten-Tipps & -Tricks profitieren</li></ul>"
    />
    <Kpi
      headline="Warum VGH?"
      copy="Weil ihr jemanden braucht, der <strong>da, nah, fair</strong> ist."
      kpiItems={KpiItems}
      btnTarget="https://www.vgh.de/content/privat/service/betreuersuche/"
      btnText="Berater finden"
    />
  </Layout>
);

export default HomePage;
