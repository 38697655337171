import React from "react";
import Icon from "../../components/ui/Icon";
import * as styles from "./styles.module.scss";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.wrapper}>
        <div className={styles.upperWrapper}>
          <p className={styles.text}>
            <span className="text-12">WENN DANN VGH</span>
            <br />
            Wenn Sie gerne persönlich beraten werden, leistungsstarke Produkte
            zu fairen Beiträgen gut finden und wenn Sie sich im Schadenfall auf
            schnelle und unkomplizierte Hilfe verlassen wollen, dann haben Sie
            mit uns den richtigen Partner an Ihrer Seite. Einen Partner, der
            sich nicht nur für Sie und die Menschen in Niedersachsen stark
            macht, sondern auch für Sport, Kultur, Soziales und die Feuerwehren
            in Ihrer Region. WENN, DANN VGH. Fair versichert.
            <br />
            <br />
            *Wenn Sie sich für den VGH-Newsletter angemeldet haben und diesen
            bis einschließlich 31.12.2023 abonnieren, können Sie am Gewinnspiel
            teilnehmen.
          </p>
          <div className={styles.iconWrapper}>
            <a
              href="https://www.facebook.com/VGHVersicherungen/"
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name="facebook"
                width={22}
                additionalClass="text-whiteGray"
              />
            </a>
            <a
              href="https://www.instagram.com/vgh_versicherungen/"
              target="_blank"
              rel="noreferrer"
            >
              <Icon
                name="instagram"
                width={22}
                additionalClass="text-whiteGray"
              />
            </a>
          </div>
        </div>
        <div className={styles.belowWrapper}>
          <div className={styles.text}>
            <span>© 2023 VGH Versicherungen</span>{" "}
            <a
              href="https://www.vgh.de/content/impressum/"
              target="_blank"
              rel="noreferrer"
            >
              Impressum
            </a>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <a
              href="https://www.vgh.de/content/datenschutz/"
              target="_blank"
              rel="noreferrer"
            >
              Datenschutz
            </a>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <a
              href="https://www.vgh.de/kontakt"
              target="_blank"
              rel="noreferrer"
            >
              Kontakt
            </a>
            &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
            <a title="Cookie-Einstellungen">Cookie-Einstellungen</a>
          </div>
          <div className={styles.logoWrapper}>
            <Icon name="sparkasse" width={105} />
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
