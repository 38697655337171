import React from 'react';

import CheckIcon from '../Icon/icons/check.svg';
import * as styles from './styles.module.scss';
import Text from '../../content/Text';

type Props = {
  headline: string;
  text: string;
  closeModal?: VoidFunction;
};

const Modal: React.FC<Props> = ({ headline, text, closeModal = () => {} }) => {
  return (
    <>
      <div aria-hidden className={styles.darkBG} onClick={closeModal} />
      <div className={styles.wrapper}>
        <div className={styles.modal}>
          <div role="button" aria-hidden className={styles.closeBtn} onClick={closeModal}>
            <span style={{ marginBottom: '-3px' }}>X</span>
          </div>
          <div className={styles.modalContent}>
            <CheckIcon className={styles.image} />
            <Text headline={headline} text={text} colSpan={12} centered isInsideModal />
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
