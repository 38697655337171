// extracted by mini-css-extract-plugin
export var btn = "styles-module--btn--a1990";
export var headline = "styles-module--headline--40459";
export var itemText = "styles-module--itemText--ad2f6";
export var itemTitle = "styles-module--itemTitle--e1271";
export var kpiAmount = "styles-module--kpiAmount--4aab7";
export var kpiCopy = "styles-module--kpiCopy--50110";
export var kpiItem = "styles-module--kpiItem--07523";
export var kpiList = "styles-module--kpiList--57bc2";
export var kpiTitle = "styles-module--kpiTitle--aa31b";
export var kpiTitleWrap = "styles-module--kpiTitleWrap--4f749";
export var kpiWrap = "styles-module--kpiWrap--1c08d";
export var textAboveHeadline = "styles-module--textAboveHeadline--29ead";