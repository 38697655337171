import React, { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import classNames from 'classnames';
import axios from 'axios';
import Headline from '../../ui/Headline';
import Col from '../../ui/Grid/Col';
import Row from '../../ui/Grid/Row';
import Container from '../../ui/Grid/Container';
import * as styles from './styles.module.scss';
import Modal from '../../ui/Modal';

type NewsletterProps = {
  headline?: string;
  copy?: string;
};

const Newsletter = ({ headline, copy }: NewsletterProps) => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(false);
  const params = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : null;
  const campaign = params ? params.get('utm_campaign') : null;
  const contract = params ? params.get('v')?.split(',') : null;

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      dataProtection: false,
    },
  });

  const onSubmit = (data: FieldValues) => {
    const { firstName, lastName, email } = data;
    axios({
      method: 'post',
      url: `${process.env.URL}/api/newsletter.php`,
      headers: { 'content-type': 'application/json' },
      data: {
        firstName,
        lastName,
        email,
        campaign,
        contract,
      },
    })
      .then(result => {
        setFormSubmitted(true);
        reset();
      })
      .catch(error => {
        setFormError(true);
      });
  };

  return (
    <Container additionalClass={styles.contactForm}>
      {headline && (
        <Row additionalClass="-mb-[38px] tablet:-mb-[12px] text-center justify-self-center max-w-[700px] mx-auto px-20">
          <Col mobile={12} tablet={12} tabletLandscape={10} desktop={12}>
            <Headline additionalClass={styles.mainHeading} classLevel={1}>
              <span dangerouslySetInnerHTML={{ __html: headline }} />
            </Headline>
            {copy && <p className={styles.contentPara}>{copy}</p>}
          </Col>
        </Row>
      )}
      <Row additionalClass={classNames(styles.row)}>
        <Col mobile={12} tablet={12} tabletLandscape={12} desktop={12} additionalClass={styles.formCol}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row additionalClass={styles.inputSection}>
              <Col mobile={12} tablet={12} tabletLandscape={6} desktop={6}>
                <label htmlFor="firstName" className={styles.inputLabel}>
                  Vorname
                </label>
                {errors.firstName && <span className={styles.errorMessage}>Bitte geben Sie Ihren Vornamen ein.</span>}
                <input
                  className={classNames(styles.inputField, {
                    [styles.inputError]: errors.firstName,
                  })}
                  type="text"
                  id="firstName"
                  placeholder="Ihr Vorname …"
                  autoComplete="given-name"
                  {...register('firstName', { required: false })}
                />
              </Col>
            </Row>
            <Row additionalClass={styles.inputSection}>
              <Col mobile={12} tablet={12} tabletLandscape={6} desktop={6}>
                <label htmlFor="lastName" className={styles.inputLabel}>
                  Nachname
                </label>
                {errors.lastName && <span className={styles.errorMessage}>Bitte geben Sie Ihren Nachnamen ein.</span>}
                <input
                  className={classNames(styles.inputField, {
                    [styles.inputError]: errors.lastName,
                  })}
                  type="text"
                  id="lastName"
                  placeholder="Ihr Nachname …"
                  autoComplete="family-name"
                  {...register('lastName', { required: false })}
                />
              </Col>
            </Row>
            <Row additionalClass={styles.inputSection}>
              <Col mobile={12} tablet={12} tabletLandscape={6} desktop={6}>
                <label htmlFor="email" className={styles.inputLabel}>
                  E-Mail *
                </label>
                {errors.email && <span className={styles.errorMessage}>Bitte geben Sie Ihre E-Mail-Adresse ein.</span>}
                <input
                  className={classNames(styles.inputField, {
                    [styles.inputError]: errors.email,
                  })}
                  type="email"
                  id="email"
                  placeholder="Ihre E-Mail-Adresse …"
                  autoComplete="email"
                  {...register('email', { required: true })}
                />
              </Col>
            </Row>
            <Row additionalClass={styles.dataProtection}>
              <Col mobile={12} tablet={12} tabletLandscape={6} desktop={6}>
                <p className="text-privacyGray text-12 leading-14 pt-20">* Pflichtfeld</p>
              </Col>
              <Col mobile={12} tablet={12} tabletLandscape={6} desktop={6}>
                {errors.dataProtection && <span className={styles.errorMessage}>Dies ist ein Pflichtfeld.</span>}
                <div className="flex gap-x-10">
                  <div>
                    <input
                      type="checkbox"
                      id="dataProtection"
                      className={styles.dataProtectionCheckbox}
                      {...register('dataProtection', { required: true })}
                    />
                  </div>
                  <label htmlFor="dataProtection" className={styles.dataProtectionLabel}>
                    Hiermit erkläre ich mich einverstanden, dass die VGH Versicherungen (Landschaftliche Brandkasse
                    Hannover, Provinzial Lebensversicherung Hannover, Provinzial Krankenversicherung Hannover) mir
                    Werbung zu den Versicherungsprodukten & weiteren Services der VGH Versicherungen an die angegebene
                    E-Mail-Adresse zusenden darf. Die Verarbeitung personenbezogener Daten erfolgt entsprechend der
                    hinterlegten{' '}
                    <a href="https://www.vgh.de/datenschutz" target="_blank" rel="noreferrer" className="text-blue">
                      Datenschutzerklärung
                    </a>{' '}
                    einschließlich Informationen zur Personalisierung des Newsletters. Die Einwilligung kann ich
                    jederzeit per E-Mail an{' '}
                    <a href="mailto:marketing@vgh.de" target="_blank" rel="noreferrer" className="text-blue">
                      marketing@vgh.de
                    </a>{' '}
                    oder über den in jedem Newsletter enthaltenen Abmeldelink widerrufen.
                  </label>
                </div>
              </Col>
              <Col
                mobile={12}
                tablet={12}
                tabletLandscape={6}
                desktop={6}
                additionalClass="justify-self-center tabletLandscape:flex tabletLandscape:justify-self-start w-full tabletLandscape:w-auto"
              >
                {formError && (
                  <p
                    className={'mr-20 mb-auto mt-auto ml-0 leading-[15px] text-14 text-red pb-10 tabletLandscape:pb-0'}
                  >
                    Ups. Da hat wohl etwas nicht geklappt. Bitte überprüfen Sie ihre Daten und versuchen Sie es erneut.
                  </p>
                )}
                <button className={styles.btnStyles} type="submit">
                  Absenden
                </button>
              </Col>
            </Row>
          </form>
          {formSubmitted && (
            <Modal
              headline="Vielen Dank für Ihre Newsletter-Anmeldung."
              text="<p>Wir haben eine Bestätigungsmail an Ihre E-Mail-Adresse gesendet. Diese werden Sie innerhalb der nächsten Minuten zugesendet bekommen. Sollten Sie keine E-Mail erhalten, schauen Sie bitte in Ihrem Spam Ordner nach.</p>
<br/><p>Herzlichst, Ihre VGH Versicherungen</p>"
              closeModal={() => setFormSubmitted(false)}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Newsletter;
