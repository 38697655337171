// extracted by mini-css-extract-plugin
export var btnStyles = "styles-module--btnStyles--d5348";
export var contactForm = "styles-module--contactForm--61ce1";
export var contentPara = "styles-module--contentPara--d5f45";
export var dataProtection = "styles-module--dataProtection--8c70f";
export var dataProtectionCheckbox = "styles-module--dataProtectionCheckbox--f22b2";
export var dataProtectionLabel = "styles-module--dataProtectionLabel--d6139";
export var errorMessage = "styles-module--errorMessage--a0af5";
export var formCol = "styles-module--formCol--6b388";
export var inputError = "styles-module--inputError--0435a";
export var inputField = "styles-module--inputField--25e0c";
export var inputLabel = "styles-module--inputLabel--33f1d";
export var inputSection = "styles-module--inputSection--6eba4";
export var mainHeading = "styles-module--mainHeading--ff7ea";
export var radioField = "styles-module--radioField--b459c";
export var radioLabel = "styles-module--radioLabel--29b05";
export var row = "styles-module--row--4b609";
export var sectionHeadline = "styles-module--sectionHeadline--f042b";
export var textAreaField = "styles-module--textAreaField--75c89";